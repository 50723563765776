import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames/bind';

// Components
import Heading from '../../../../components/Heading/DesktopView';

// Icons
import FilterIcon from '../../../../icons/filter.svg';
import RefreshIcon from '../../../../icons/refresh.svg';

// State
import { fetchCatalogActiveIngredientsIfNeeded } from '../../../../state/modules/catalog/actions';

// Styles
import styles from './PizzaFilter.styl';
import OpenIcon from '../../../../icons/toggle2.svg';
import CloseIcon from '../../../../icons/toggle1.svg';

const cx = classNames.bind(styles);
import { CRUST_EXTRA, CRUST_EXTRA_EN, CRUST_EXTRA_PL } from '../../../../utils/constants';

export default function PizzaFilter(props) {
  const { goods, changeFilterPizza, className, name, id } = props;

  const dispatch = useDispatch();
  const pizzaFilter = useSelector(state => state.catalog.pizzaFilter);
  const isNewYearMode = useSelector(state => !!state.city.userCity.is_new_year_mode_on);

  const [filterId, setFilterId] = useState(null);
  // выбранные ингредиенты для фильтрации
  const [ingredientsIds, setIngredientsIds] = useState([]);
  // после выбора игредиента смотрим, какие остались доступными
  const [availableIngredientsIds, setAvailableIngredientsIds] = useState({});
  const [pizzaFilterIsOpen, setPizzaFilterIsOpen] = useState(false);
  const [openSelect, setOpenSelect] = useState('');
  // состояние для хранения ширины селектора в фильтре
  const [widthForButton, setWidthForButton] = useState('');

  const refButtonFilter = useRef(null);

  const types = [];

  // извлекаем все типы из пицц
  goods.forEach(good => {
    if (good.types) {
      good.types.forEach(type => {
        types.push(type.name);
      });
    }
  });

  // избавляемся от дублей
  const filtredExpressButtons = [...new Set(types)];

  const changeFilter = () => {
    // фильтруем пиццы по ингредиентам и типам
    const filteredGoods = goods.filter(good => {
      // если хоть в какой-то из вариаций пиццы имеется каждый выбранный игредиент
      const isIngredientsChecked = (good.variations || []).some(variation =>
        ingredientsIds.every(ingredientsId =>
          (variation.include_ingredients || [])
            .map(includeIngredient => Number(includeIngredient.id))
            .includes(ingredientsId)
        )
      );

      // если выбранная пицца по типу подходит под экспресс-фильтр
      const isExpressFilterChecked = (good.types || []).some(type => type.name === filterId);

      return (ingredientsIds.length === 0 || isIngredientsChecked) &&
        (!filterId || isExpressFilterChecked);
    });

    // Находим уникальные id ингридиентов которые остались после фильтрации товаров
    const newAvailableIngredientsIds = {};

    filteredGoods.forEach(good => {
      (good.variations || []).forEach(variation => {
        (variation.include_ingredients || []).forEach(includeIngredient => {
          newAvailableIngredientsIds[includeIngredient.id] = true;
        });
      });
    });

    setAvailableIngredientsIds(newAvailableIngredientsIds);
    changeFilterPizza(filteredGoods.map(filteredGood => filteredGood.id));
  };

  // выбираем фильтр на экспресс кнопку
  const onSelect = itemId => {
    const newFilterId = filterId === itemId ? null : itemId;

    setFilterId(newFilterId);
    setIngredientsIds([]);
  };

  const onSelectIngredients = ingredientId => {
    if (!availableIngredientsIds[ingredientId]) {
      return;
    }

    // если уже содержится, то удаляем, а если нет, то добавляем
    const newIngredientsIds = ingredientsIds.includes(ingredientId)
      ? ingredientsIds.filter(id => ingredientId !== id)
      : [...ingredientsIds, ingredientId];

    setIngredientsIds(newIngredientsIds);
  };

  // сбрасываем фильтры
  const refresh = () => {
    setFilterId(null);
    setIngredientsIds([]);
  };

  // выбираем ингредиент

  // открываем меню ингредиентов
  const pizzaFilterToggle = () => {
    setOpenSelect('');
    return setPizzaFilterIsOpen(prev => !prev);
  };

  useEffect(() => {
    dispatch(fetchCatalogActiveIngredientsIfNeeded());
  }, []);

  useEffect(() => {
    changeFilter();
  }, [filterId, ingredientsIds]);

  useLayoutEffect(() => {
    if (openSelect) {
      setWidthForButton(`${refButtonFilter.current?.getBoundingClientRect().width + 50}px`);
    } else {
      setWidthForButton('auto');
    }
  }, [openSelect]);

  return (
    <div className={cx('PizzaFilter', className, {
      'PizzaFilter__new-year': isNewYearMode
    })} id={id && id}>
      <div className={cx('PizzaFilter__actions')}/>
      {/* кнопки быстрого выбора */}
      <div className={cx('PizzaFilter__wrapper')}>
        <div
          className={cx('PizzaFilter__express')}
          style={pizzaFilterIsOpen ? {height: 'auto'} : {height: 60, overflow: 'hidden'}}
        >
          <div className={cx('PizzaFilter__filter')}>Filter</div>
          <div className={cx('PizzaFilter__tags-list')}>
            {filtredExpressButtons
              .sort((a, b) => a.length - b.length)
              .map(value => (
                <button
                  key={value}
                  onClick={() => onSelect(value)}
                  type="button"
                  className={cx('PizzaFilter__express-button', {
                    'PizzaFilter__express-button_selected': filterId === value
                  })}
                >
                  {value}
                </button>
              ))}
            {Object.keys(pizzaFilter.activeIngredients).filter(type => type !== CRUST_EXTRA && type !== CRUST_EXTRA_EN && type !== CRUST_EXTRA_PL)
              .map(type => (
                <button
                  key={type}
                  type="button"
                  className={cx('PizzaFilter__express-select', {
                    'PizzaFilter__express-select_open': openSelect === type
                  })}
                  style={{position: 'relative', width: openSelect === type && widthForButton}}
                  onClick={() => {
                    if (openSelect === '' || openSelect !== type) {
                      setOpenSelect(type);
                      setPizzaFilterIsOpen(true)
                    }
                  }}
                >
                  <div
                    onClick={() => {
                      if (openSelect === type) {
                        setOpenSelect('');
                      } else {
                        setOpenSelect(type);
                      }
                    }}
                  >
                    {type}
                  </div>
                  {openSelect === type ? <OpenIcon/> : <CloseIcon/>}
                  {openSelect === type
                    ? <div
                      className={cx('PizzaFilter__express-select_select-block')}
                      ref={refButtonFilter}>
                      {pizzaFilter.activeIngredients[type].map(item => {
                        return (
                          <div
                            className={cx({
                              'PizzaFilter__express-select_selected': ingredientsIds.includes(item.id),
                              'PizzaFilter__express-select_removed': !availableIngredientsIds[item.id]
                            })}
                            onClick={() => onSelectIngredients(item.id)}
                          >
                            {item.name}
                          </div>
                        );
                      })}
                    </div>
                    : null}
                </button>
              ))}
            <button
              type="button"
              className={cx('PizzaFilter__express-button', 'PizzaFilter__express-button-refresh', {
                PizzaFilter__action_invisible: ingredientsIds.length === 0 && !filterId
              })}
              onClick={refresh}
            >
              <RefreshIcon width={20} height={20} fill="#70544F"/>
            </button>
          </div>
          <button
            type="button"
            className={cx('PizzaFilter__action', 'PizzaFilter__action_toggle', {
              PizzaFilter__action_active: pizzaFilterIsOpen
            })}
            onClick={pizzaFilterToggle}
          >
            <FilterIcon width={17} height={16}/>
          </button>
        </div>
      </div>
      <div className={cx('PizzaFilter__heading-wrapper')}>
        <Heading className={cx('PizzaFilter__heading')} level={2} tagName="h4">
          {name}
        </Heading>
        <div className={cx('PizzaFilter__heading-img')}/>
      </div>
    </div>
  );
}

PizzaFilter.defaultProps = {
  className: ''
};

PizzaFilter.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  changeFilterPizza: PropTypes.func.isRequired,
  goods: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired
};
